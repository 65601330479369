import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import * as Scroll from 'react-scroll'
let ScrollLink  = Scroll.Link;
let Element   = Scroll.Element;
let Events    = Scroll.Events;
let scroll    = Scroll.animateScroll;
let scrollSpy = Scroll.scrollSpy;
let scroller = Scroll.scroller;

import { Layout, Section, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import {
    motion,
	transform,
} from 'framer-motion'

const Teachers = (props) => {
	const { x, y } = useWindowScroll();
	const { width, height } = useWindowSize()

	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

	// Scroll-to Easing

    const handleClick = (e, to) => {
        e.preventDefault()
        e.stopPropagation()

        scroller.scrollTo(to, {
            duration: 1500,
            smooth: 'easeInOutCubic',
        })
    }

	// Intro

	const renderIntro = () => {
		return (
			<Intro>
				<Wrap>
					<Title>{data.title}</Title>
					<Text dangerouslySetInnerHTML={{ __html: data.introduction }} />
				</Wrap>
				<Wrap>
					{data.hero_image && (
						<Image
							key={data.hero_image?.sizes?.medium2}
							src={data.hero_image?.sizes?.medium2}
						>
							{(src, loading) => {
								return (
									<BGImage
										image={src}
										style={{ opacity: loading ? 0 : 1 }}
									/>
								)
							}}
						</Image>
					)}
				</Wrap>
			</Intro>
		)
	}

	// Jump Nav

	const renderJumpNav = () => {
		const items = data.classes && data.classes.map((item, i) => {
			return (
				<ScrollLink
					key={i}
					to={item.type.slug}
					spy={true}
                    duration={500}
                    className="list-item"
                    activeClass="active"
					onClick={(e) => handleClick(e, item.type.slug)}
				>
					{item.type.title}
				</ScrollLink>
			)
		})

		return (
			<List>
				<Text>Jump to:</Text>
				{items}
			</List>
		)
	}

	// Classes

	const renderClasses = () => {
		return data.classes && data.classes.map((item, i) => {
			return (
				<Classes id={item.type.slug}>
					<Wrap>
						<Heading>{item.type.title}</Heading>
						<Text
							dangerouslySetInnerHTML={{__html: item.type.description}}  
						/>
					</Wrap>
					{renderClassItem(item.items, item.type.image)}
				</Classes>
			)
		})
	}
	
	const renderClassItem = (classes, image) => {
        if (!classes) return

        const items = classes.map((item, i) => {
            return (
				<ClassItem key={i}>
					<Image
						key={image?.sizes?.medium2}
						src={image?.sizes?.medium2}
					>
						{(src, loading) => {
							return (
								<BGImage
									image={src}
									style={{ opacity: loading ? 0 : 1 }}
								/>
							)
						}}
					</Image>

					<Meta>
						<Heading>{item.title}</Heading>
						<SubHeading dangerouslySetInnerHTML={{ __html: item.acf.sub_heading }} />
						<Text dangerouslySetInnerHTML={{ __html: item.acf.description }} />
						<Button>Read more</Button>
					</Meta>
                </ClassItem>
            )
        })

        return <ClassList>{items}</ClassList>
    }

	return (
		<Layout
             meta={data && data.seo}
        >
			<Container>
				{renderIntro()}

				{renderJumpNav()}

				{renderClasses()}
					
				<Block
					layout={'big_button'}
					display
				/>
			</Container>
		</Layout>	
	)
}

// Shared

const Title = styled.h1``
const Heading = styled.div``
const SubHeading = styled.div``
const Wrap = styled.div``
const ListItem = styled.div``
const Text = styled.div``
const Meta = styled.div``
const Button = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
	Margin-top: 200px;
`

// Intro

const Intro = styled.div`
	margin-bottom: 150px;
	display: flex;

	${Wrap} {
		flex-basis: 50%;

		${Title} {
			${type.heading1};
			padding-bottom: 32px;
		}
		${Text} {
			${type.body};
		}
		${BGImage} {
			${bgIcon};
			height: 100%;
			border-radius: 100%;
		}
	}
`

// Jump Nav

const List = styled.div`
	display: flex;
	flex-direction: column;

	${Text} {
		font-size: 21px;
		padding-bottom: 20px;
	}

	.list-item {
		${button};
		color: ${colours.black};
		margin-bottom: 12px;

		&:visited {
			color: inherit;
		}
	}
`

// Classes

const Classes = styled.div`
	margin-bottom: 90px;

	${Wrap}{
		${Heading}{
			${type.heading1};
			padding-bottom: 24px;
		}
		${Text}{
			${type.bodySmall};
			max-width: 80%;
		}
	}
`

const ClassList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

const ClassItem = styled.div`
	width: 100%;
	max-width: 915px;

	display: flex;
	background: ${colours.lightBeige};
	transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	border-radius: 22px;

	padding: 38px;
	box-sizing: border-box;
	margin-bottom: 20px;

	&:hover {
		background: ${colours.lightBlue};
	}

	${BGImage}{
		flex-basis: 50%;
		min-width: 280px;
		min-height: 238px;
		height: 100%;
	}

	${Meta}{
		flex-basis: auto;
		padding-left: 30px;

		${Heading}{
			${type.heading3};
			border-bottom: 3px solid ${colours.black};
			padding-bottom: 20px;
		}

		${SubHeading}{
			font-size: 21px;
			padding: 20px 0;
		}

		${Text}{
			p {
				${type.bodySmall};
			}
		}

		${Button}{
			${type.bodySmall};
			text-decoration: underline;
		}
	}
`

// Data

export const query = graphql`
     query {
         allWordpressInfopages(filter: { slug: { eq: "team" } }) {
             nodes {
                 acf_json
				 title
             }
         }
     }
`

export default Teachers